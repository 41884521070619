import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timetracker',
  templateUrl: './timetracker.component.html',
  styleUrls: ['./timetracker.component.scss']
})
export class TimetrackerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
