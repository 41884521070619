import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MessageArrivalComponent } from './message-arrival/message-arrival.component';
import { ComcentreComponent } from './comcentre/comcentre.component';
import { TimetrackerComponent } from './timetracker/timetracker.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ArrivalNoMessageComponent } from './arrival-no-message/arrival-no-message.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'home', component: MessageArrivalComponent },
  { path: 'homeclear', component: ArrivalNoMessageComponent },
  { path: 'Comhub', component: ComcentreComponent },
  { path: 'timetracker', component: TimetrackerComponent },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
