import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageArrivalComponent } from './message-arrival/message-arrival.component';
import { ComcentreComponent } from './comcentre/comcentre.component';
import { TimetrackerComponent } from './timetracker/timetracker.component';
import { LoginComponent } from './login/login.component';
import { ArrivalWishMessageComponent } from './arrival-wish-message/arrival-wish-message.component';
import { ArrivalNoMessageComponent } from './arrival-no-message/arrival-no-message.component';

@NgModule({
  declarations: [
    AppComponent,
    MessageArrivalComponent,
    ComcentreComponent,
    TimetrackerComponent,
    LoginComponent,
    ArrivalWishMessageComponent,
    ArrivalNoMessageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
